import React from 'react';

import BusinessDashboardModule from '@/components/page/BusinessDashboard';

const BusinessDashboardPage = (props) => {
  return <BusinessDashboardModule {...props} />;
};

BusinessDashboardPage.withInitialProps = BusinessDashboardModule.withInitialProps;

BusinessDashboardPage.isPrivatePage = true;
BusinessDashboardPage.withStickyFooter = true;
BusinessDashboardPage.isFullWidthPage = true;
BusinessDashboardPage.pageBackground = 'var(--color-white-lightest)';

export default BusinessDashboardPage;
